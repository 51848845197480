$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});


Vue.component(`citacoes-home`, {
    data() {
        return {
            listURL: `${_spPageContextInfo.webServerRelativeUrl}/lists/Citacoes`,
            citacoes: []
        }
    },
    template:`
    <element>
        <div class="container">
            <div class="slick-citacoes">
                <div v-for="item in citacoes">
                    <div class="row d-flex justify-content-center align-items-center">
                        <div class="col-sm-5 quotes text-center">
                            <h3 class="frase">{{ item.Reconhecimento }}</h3>
                            <p class="autor">{{ item.Title }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <a href="javascript:" class="btn-prev-citacao"></a>
                <a href="javascript:" class="btn-next-citacao"></a>
            </div>
        </div>
    </element>
    `,
    methods: {
        getCitacao(){
            return $pnp.sp.web.getList(this.listURL).items
                .filter(`Ativo eq 1`)
                .orderBy(`Created`, false)
                .select(`Title,Ativo,Reconhecimento`)
                .top(100)
                .get().then((results) => {
                    Vue.set(this, `citacoes`, results);
                    if(results.length > 1){
                        this.applySlick()
                    }
                }).catch((err) => {
                    this.threatError(err, `Erro ao carregar citação`)
                })
        },
        applySlick(){
            Vue.nextTick(() => {
                $(document).ready(() => {
                    $(`.slick-citacoes`).slick({
                        arrows:true,
                        dots: false,
                        slidesToShow:1,
                        slidesToScroll:1,
                        swipe:false,
                        autoplay:true,
                        speed:800,
                        autoplaySpeed: 5000,
                        prevArrow: $(`.btn-prev-citacao`),
                        nextArrow: $(`.btn-next-citacao`),
                    })
                })
            })
        },
        threatError(err, msg){
            console.log(err);
            this.errorMsg = msg;
        }
    },
    created() {
        this.getCitacao();
    },
});