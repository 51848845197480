$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

Vue.component(`noticias-home`, {
    mixins: [translateMixin],
    data() {
        return {
            listURL: `${_spPageContextInfo.webServerRelativeUrl}/lists/Noticias`,
            noticias: [],
            artigos: [],
            clippings: [],
            msgError: null,
            tabs: {
                noticias: {
                    Title: `Notícias`,
                    TitleEN: `News`
                },
                artigos: {
                    Title: `Artigos`,
                    TitleEN: `Articles`
                },
                clippings: {
                    Title: `Clippings`,
                    TitleEN: `Clippings`
                }
            },
            moment: moment,
        }
    },
    template: `
    <element>
        <div class="container">
            <ul class="nav nav-pills d-flex justify-content-center mb-5" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="clippings-tab" data-toggle="pill" href="#clippings" role="tab" aria-controls="clippings" aria-selected="true">{{ isEnglish ? tabs.clippings.TitleEN : tabs.clippings.Title  }}</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="artigos-tab" data-toggle="pill" href="#artigos" role="tab" aria-controls="artigos" aria-selected="false">{{ isEnglish ? tabs.artigos.TitleEN : tabs.artigos.Title  }}</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="noticias-tab" data-toggle="pill" href="#noticias" role="tab" aria-controls="noticias" aria-selected="false">{{ isEnglish ? tabs.noticias.TitleEN : tabs.noticias.Title }}</a>
                </li>
                
            </ul>

            <div class="row">
                <div class="col-lg-10 mx-auto">
                    <div class="tab-content align-items-center noticias-content" id="pills-tabContent">

                        <div class="tab-pane fade show active" id="clippings" role="tabpanel" aria-labelledby="clippings-tab">
                            <template v-if="clippings.length">
                                <div class="row noticia" v-for="item in clippings" v-if="(isEnglish && item.TitleEN) || (!isEnglish && item.Title)">
                                    <div class="col-md-2">
                                        <div class="data-post">{{ moment(item.Data).format('DD/MM/YYYY') }}</div>
                                        <div class="categoria-post">{{ item.SubCategoria.Title }}</div>
                                    </div>
                                    <div class="col-sm-10 title">
                                        <a :href="getDispItem(item.SeoURL)" :title="item.Title">{{ isEnglish ? item.TitleEN : item.Title }}</a>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="tab-pane fade" id="artigos" role="tabpanel" aria-labelledby="artigos-tab">
                            <template v-if="artigos.length">
                                <div class="row noticia" v-for="item in artigos" v-if="(isEnglish && item.TitleEN) || (!isEnglish && item.Title)">
                                    <div class="col-md-2">
                                        <div class="data-post">{{ moment(item.Data).format('DD/MM/YYYY') }}</div>
                                        <div class="categoria-post">{{ item.SubCategoria.Title }}</div>
                                    </div>
                                    <div class="col-sm-10 title">
                                        <a :href="getDispItem(item.SeoURL)" :title="item.Title">{{ isEnglish ? item.TitleEN : item.Title }}</a>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div class="tab-pane fade" id="noticias" role="tabpanel" aria-labelledby="noticias-tab">
                            <template v-if="noticias.length">
                                <div class="row noticia" v-for="item in noticias" v-if="(isEnglish && item.TitleEN) || (!isEnglish && item.Title)">
                                    <div class="col-md-2">
                                        <div class="data-post">{{ moment(item.Data).format('DD/MM/YYYY') }}</div>
                                        <div class="categoria-post">{{ item.SubCategoria.Title }}</div>
                                    </div>
                                    <div class="col-sm-10 title">
                                        <a :href="getDispItem(item.SeoURL)" :title="item.Title">{{ isEnglish ? item.TitleEN : item.Title }}</a>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <p class="text-center">{{ isEnglish ? 'There are no news registered at the moment.' : 'Não existem notícias cadastradas no momento.' }}</p>
                            </template>
                        </div>

                        
                    </div>  
                </div>   
            </div>     
            
            <div class="d-flex align-items-center justify-content-center my-3">
                <a :href="_spPageContextInfo.siteAbsoluteUrl + '/noticias'" title="" class="link-icon mais-itens">
                    <div class="button-icon ic-plus block"></div>
                    {{ isEnglish ? 'MORE NEWS' : 'MAIS NOTÍCIAS' }}
                </a>
            </div>
        </div>
    </element>
    `,
    methods: {
        getNoticias() {
            return $pnp.sp.web.getList(this.listURL).items
                .select(`Title,TitleEN, Data,AuxCategoria/Title, SubCategoria/Title,SeoURL`)
                .expand(`AuxCategoria, SubCategoria`)
                .filter(`AuxCategoria/Title eq 'Notícias'`)
                .orderBy(`Data`, false)
                .top(5)
                .get().then((results) => {
                    Vue.set(this, `noticias`, results)
                })
        },
        getArtigos() {
            return $pnp.sp.web.getList(this.listURL).items
                .select(`Title,TitleEN, Data,AuxCategoria/Title,SubCategoria/Title,SeoURL`)
                .expand(`AuxCategoria,SubCategoria`)
                .filter(`AuxCategoria/Title eq 'Artigos'`)
                .orderBy(`Data`, false)
                .top(5)
                .get().then((results) => {
                    Vue.set(this, `artigos`, results)
                })
        },
        getClippings() {
            return $pnp.sp.web.getList(this.listURL).items
                .select(`Title,TitleEN, Data,AuxCategoria/Title,SubCategoria/Title,SeoURL`)
                .expand(`AuxCategoria,SubCategoria`)
                .filter(`AuxCategoria/Title eq 'Clippings'`)
                .orderBy(`Data`, false)
                .top(5)
                .get().then((results) => {
                    Vue.set(this, `clippings`, results)
                })
        },
        getDispItem(url) {
            return `${_spPageContextInfo.siteAbsoluteUrl}/noticias/${url}`;
        },
    },
    created() {
        this.getNoticias()
            .then(this.getArtigos)
            .then(this.getClippings)
    },
})