window.methodsBannerText = {
    methods: {
        alinhamentoConteudo(alinhamento){
            if(alinhamento == 'Esquerda Superior'){
                return 'position-left-top'
            }else if(alinhamento == 'Esquerda Inferior'){
                return `position-left-bottom`
            }else if(alinhamento == 'Centro'){
                return 'position-center'
            }else if(alinhamento == `Direita Superior`){
                return `position-right-top`
            }else if(alinhamento == `Direita Inferior`){
                return `position-right-bottom`
            }
        },
        alinhamentoTexto(alinhamento){
            if(alinhamento == 'Esquerda'){
                return 'text-left'
            }else if(alinhamento == 'Centro'){
                return 'text-center'
            }else if(alinhamento == `Direita`){
                return `text-right`
            }
        },
        estilizarTitulo(item) {
            var fonteTitulo = '';
            var corTitulo = '';

            if (item.TamanhoFonteTitulo)
                fonteTitulo = item.TamanhoFonteTitulo
            if (item.CorTitulo.Cor)
                corTitulo = item.CorTitulo.Cor

            return `font-size: ${fonteTitulo};` + `color: ${corTitulo};`
        },
        estilizarSubTitulo(item) {
            var fonteSubtitulo = '';
            var corSubtitulo = '';

            if (item.TamanhoFonteTexto)
                fonteSubtitulo = item.TamanhoFonteTexto
            if(item.CorSubTitulo.Cor)
                corSubtitulo = item.CorSubTitulo.Cor
            
            return `font-size: ${fonteSubtitulo};` + `color: ${corSubtitulo};`
        },
        estilizarTexto(item) {
            var fonteTexto = '';
            var corTexto = '';

            if (item.TamanhoFonteTexto)
                fonteTexto = item.TamanhoFonteTexto
            if(item.CorDescricao.Cor)
                corTexto = item.CorDescricao.Cor
            
            return `font-size: ${fonteTexto};` + `color: ${corTexto};`
        },
        estilizarBotao(item) {
            var corBordaBotao = '';
            var corBotaoTxt = '';

            if (item.CorBotao) {
                corBordaBotao = item.CorBotao.Cor;
            }
            if (item.CorBotao) {
                corBotaoTxt = item.CorBotao.Cor;
            }
            return `border-color: ${corBordaBotao}!important;` + `color: ${corBotaoTxt};`
        }
    }
}