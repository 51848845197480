window.translateMixin = {
    methods: {
        changeLanguage(value) {
            if (CurrentLanguageDecimal == value) {
                return null;
            }

            var today = new Date();
            var oneYear = new Date(today.getTime() + 365 * 24 * 60 * 60 * 1000);
            var url = window.location.href;
            document.cookie = "lcid=" + value + ";path=/;expires=" + oneYear.toGMTString();
            location.href = url;
            location.reload();
        }
    },
    data() {
        return {
            isEnglish: CurrentLanguageDecimal == 1033,
            currentLang: CurrentLanguageDecimal
        };
    }
}