$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

Vue.component(`atuacao-home`, {
    mixins:[translateMixin],
    data() {
        return {
            listURL: `${_spPageContextInfo.webServerRelativeUrl}/lists/AreasAtuacao`,
            areasAtuacao: [],
            industria: [],
            msgError: null,
            tabs: {
                atuacao: {
                    Title: `Áreas de Atuação`,
                    TitleEN: `Practice Areas`
                },
                industrias: {
                    Title: `Indústrias`,
                    TitleEN: `Industry`
                }
            }
        }
    },
    template:`
    <element id="areas-atuacao">
        <div class="container">
            <!-- abas -->

            <ul class="nav nav-pills d-flex justify-content-center" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="atuacao-tab" data-toggle="pill" href="#atuacao" role="tab" aria-controls="atuacao" aria-selected="true">{{ isEnglish ? tabs.atuacao.TitleEN : tabs.atuacao.Title }}</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="industria-tab" data-toggle="pill" href="#industria" role="tab" aria-controls="industria" aria-selected="false">{{ isEnglish ? tabs.industrias.TitleEN : tabs.industrias.Title  }}</a>
                </li>
            </ul>

            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="atuacao" role="tabpanel" aria-labelledby="atuacao-tab">
                    <template v-if="msgError">
                        <p class="text-center">{{ msgError }}</p>
                    </template>
                    <template v-else>
                        <div class="row areas">
                            <div class="col-lg-4 mb-3" v-for="item in areasAtuacao">
                                <a :href="getDispItem(item.SeoURL)" class="box">
                                    <img :src="item.LinkImagem" :alt="item.Title" />
                                    <span>{{ isEnglish ? item.TitleEN : item.Title }}</span>
                                </a>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="tab-pane fade" id="industria" role="tabpanel" aria-labelledby="industria-tab">
                    <template v-if="msgError">
                        <p class="text-center">{{ msgError }}</p>
                    </template>
                    <template v-else>
                        <div class="row areas">
                            <div class="col-sm-4 mb-3" v-for="item in industria">
                                <a :href="getDispItem(item.SeoURL)" class="box">
                                    <img :src="item.LinkImagem" :alt="item.Title" />
                                    <span>{{ isEnglish ? item.TitleEN : item.Title }}</span>
                                </a>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </element>
    `,
    methods: {
        getAreasAtuacao(){
            return $pnp.sp.web.getList(this.listURL).items
                .filter(`Categoria eq 'Área de Atuação' and Ativo eq 1`)
                .select(`Title, TitleEN, Ativo, LinkImagem, Ordem, SeoURL`)
                .orderBy(`Title`, true)
                .get().then((results) => {
                    Vue.set(this, `areasAtuacao`, results);
                }).catch((err) => {
                    this.threatError(err, `erro ao carregar áreas de atuação.`)
                })
        },
        getIndustrias(){
            return $pnp.sp.web.getList(this.listURL).items
                .filter(`Categoria eq 'Indústria' and Ativo eq 1`)
                .select(`Title, TitleEN, Ativo, LinkImagem, Ordem, SeoURL`)
                .orderBy(`Title`, true)
                .get().then((results) => {
                    Vue.set(this, `industria`, results);
                }).catch((err) => {
                    this.threatError(err, `erro ao carregar áreas de atuação.`)
                })
        },
        getDispItem(seoURL){
            let param = `atuacao`;
            return `${_spPageContextInfo.siteAbsoluteUrl}/${param}/${seoURL}`
        },
        threatError(err,msg){
            console.log(err);
            this.msgError = msg;
        }
    },
    created() {
        this.getAreasAtuacao().then(this.getIndustrias);
    },
});