// js utilizados na home;

require('../../mixins/translate');              // js de traduçao
require('../../mixins/pnp-mixin');              // pnp mixin
require('./banner');                            // banner home
require(`./atuacao-home`);                      // atuacao home
require(`./citacoes-home`);                     // citacoes home
require(`./reconhecimentos-home`);              // reconhecimentos home
require(`./noticias-home`);                     // noticias home
require(`./selos-home`);                        // selos home



Vue.component(`app-home`, {
    mixins: [translateMixin],
    template: `
    <element id="home">
        <section id="banner">
            <banner-home />
        </section>

        <section id="atuacao-home">
            <atuacao-home />
        </section>

        <section id="citacoes-home">
            <citacoes-home />
        </section>

        <section id="reconhecimentos-home">
            <reconhecimentos-home />
        </section>

        <section id="noticias-home">
            <noticias-home />
        </section>

        <section id="selos-home">
            <selos-home />
        </section>
    </element>
    `,
});

var app = new Vue({
    el: `#app-home`,
    template: `<app-home />`
})