$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

// importando os mixins com os metodos do banner - foram separados porque havia muita informação então preferi separar.
require('./banner/banner-texts');
require('./banner/banner-scripts');

Vue.component('banner-home',{
    template:`
    <element>
        <div class="banner-home">
            <div class="slide-banner">
                <!-- for-each items-->
                <div class="banner" v-for="item in bannerHome" :class="getCustomClass(item)" v-bind:onclick="(!item.TituloURL && item.URL) ? openURL(item) : undefined" :black-filter="getBlackFilter(item)">
                    <template v-if="item.Categoria == 'Desktop'">
                        <!-- template imagem -->
                        <template v-if="item.TipoBanner == 'Imagem'">
                            <img :src="item.LinkImagem" :alt="item.Title" />
                        </template>
                        <!-- /template imagem -->

                        <!-- template vimeo -->
                        <template v-if="item.TipoBanner == 'Vídeo' && item.TipoVideo == 'Vimeo'">
                            <div class="slide-video slide-media" data-video-start="4">
                                <iframe 
                                    class="embed-player slide-media" 
                                    :src="getUrlVideo(item.URLVideo)" 
                                    width="980" height="520" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>
                                </iframe>
                            </div>
                        </template>
                        <!-- /template vimeo -->

                        <!-- tempalte youtube -->
                        <template v-if="item.TipoBanner == 'Vídeo' && item.TipoVideo == 'Youtube'">
                            <iframe 
                                class="embed-player slide-media" 
                                :src="getUrlVideo(item.URLVideo)" 
                                width="980" height="520" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>
                            </iframe>
                        </template>
                        <!-- /template youtube -->

                        <!-- template video local -->
                        <template v-if="item.TipoBanner == 'Vídeo' && item.TipoVideo == 'Local'">
                            <video class="slide-video slide-media" loop muted preload="metadata" :poster="item.LinkImagem">
                                <source :src="getUrlVideo(item.URLVideo)"  type="video/mp4" />
                            </video>
                        </template>
                        <!-- /template video local -->
                    </template>
                    <template v-else>
                        <!-- template imagem -->
                        <template v-if="item.TipoBanner == 'Imagem'">
                            <img :src="item.LinkImagem" :alt="item.Title" class="mobile" />
                        </template>
                        <!-- /template imagem -->
                    </template>

                    <!-- setas -->
                    <div class="container">
                        <a href="javascript:" class="prev-banner">
                            <i class="arrow left"></i>
                        </a>
                        <a href="javascript:" class="next-banner">
                            <i class="arrow right"></i>
                        </a>
                    </div>
                    <!-- /setas -->

                    <div :class="'container-text ' + alinhamentoConteudo(item.AlinhamentoConteudo)" :style="fundoBox(item.FundoBox)">
                        <div class="row text-banner">
                            <div class="col-11 col-sm-12 mx-auto">
                                <div :class="'text-banner ' + alinhamentoTexto(item.AlinhamentoTexto)">
                                    <template v-if="item.ExibirTitulo == 1">
                                        <h1 v-bind:style="estilizarTitulo(item)">
                                            {{ !isEnglish ? item.Title : (item.TitleEN || item.Title) }}<br/>
                                            <span v-bind:style="estilizarSubTitulo(item)">{{ !isEnglish ? item.SubTitulo : (item.SubTituloEN || item.SubTitulo) }}</span>
                                        </h1>
                                        <div v-show="item.Descricao" v-bind:style="estilizarTexto(item)" class="mb-3 banner-descricao" v-html="!isEnglish ? item.Descricao : (item.DescricaoEN || item.Descricao)"></div>
                                    </template>
                                    <div class="btn-banner-home w-60" v-if="item.TituloURL">
                                        <a :href="getURL(!isEnglish ? item.URL : (item.URLEn || item.URL))" 
                                            :title="!isEnglish ? item.TituloURL : item.TituloURLEN" 
                                            class="btn d-sm-inline border border-secondary" 
                                            :style="estilizarBotao(item)"
                                            target="_blank"
                                        >
                                            {{ isEnglish ? item.TituloURLEN : item.TituloURL }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- buttons -->
                    <div class="bt-scroll">
                        <div class="container">
                            <span class="d-block text-center">{{ isEnglish ? 'Expertise' : 'Atuação' }}</span>
                            <a href="#areas-atuacao" class="button-scroll" :title="isEnglish ? 'Expertise' : 'Atuação'"></a>
                        </div>
                    </div>
                </div>
                <!-- /for-each items -->
            </div>
        </div>
    </element>
    `,
    mixins: [translateMixin, methodsBannerText, methodsBannerScripts],
    created: function(){
        this.getData()
            .then(this.applyEvents)
            .then(this.scriptsBanner)
    },
    methods: {
        getData: function(){
            var today = moment().format('YYYY-MM-DD[T]HH:mm:ss[Z]');
            var category = `${this.isMobile ? " and Categoria eq 'Mobile'" : " and Categoria eq 'Desktop'"}`;

            var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
            return web.getList(this.listUrl).items
                .filter(`Exibir eq 1 and DataPublicacao le datetime'${today}' and AuxIdioma/lang eq ${CurrentLanguageDecimal}${category}`)
                .select('*, AuxIdioma/Title, CorTitulo/Cor, CorSubTitulo/Cor, CorBotao/Cor, CorBotao/Cor, CorDescricao/Cor, Categoria')
                .expand('AuxIdioma, CorTitulo, CorSubTitulo, CorDescricao, CorBotao, CorBotao')
                .orderBy('Ordem',true)
                .top(100)
                .get().then((banners) => {
                    Vue.set(this, 'bannerHome', banners);
                }).catch((err) => {
                    this.threatError(err, `Erro ao obter  dados da lista ${this.listName}`);
                })
        },
        isSafari() {
            var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
            var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            if (isSafari && iOS) {
                $('#banner').css({
                    height: 'calc(100vh - 280px)'
                });
            } else if(isSafari) {
                //
            }
        },
        applyEvents: function(){
                //banner + busca height automatico
                var heightDynamic = window.innerHeight+'px';
                var userAgent = window.navigator.userAgent;

                if(window.innerWidth <= 992){
                    heightDynamic = (window.innerHeight - 90)+'px';
                    this.isSafari();
                }

                $('#banner-height').height(heightDynamic);
                // banner home - slick slider
                Vue.nextTick(function(){
                    $('.banner[bg-image]').each(function(){
                        var imgURL = $(this).attr('bg-image');
                        $(this).css('style',`${imgURL}`)
                    })
                })
        },
        openURL(item){
            if(this.isEnglish){
                return `window.open('${item.URLEn}')`;
            }else{
                return `window.open('${item.URL}')`;
            }
        },
        threatError: function(msg,data){
            console.error('error - ' + msg + ' - ' + data);
        },
        getBackgroundImage(image){
            return `background-image:url('${image}')`;
        },
        getURL(URL){
            if(URL){
                if(URL == '#'){
                    return false
                }else {
                    return URL
                }
            }
        },
        getCustomClass(item){
            if(item.TipoBanner == 'Vídeo'){
                if(item.TipoVideo == 'Vimeo'){
                    return 'vimeo'
                }else if(item.TipoVideo == 'Youtube'){
                    return 'youtube'
                }else {
                    return 'video'
                }
            }else {
                return 'image'
            }
        },
        getUrlVideo(url){
            if(url.match(/vimeo/i)){
                var id = url.split('vimeo.com/')[1];
                return `https://player.vimeo.com/video/${id}?autoplay=1&loop=1&muted=1title=0&background=1`
            }else if(url.match(/youtube/i) || url.match(/youtu.be/i)){
                var id = url.split('watch?v=')[1];
                return `https://www.youtube.com/embed/${id}?enablejsapi=1&controls=0&fs=0&iv_load_policy=3&rel=0&showinfo=0&autoplay=1&loop=1&playlist=${id}&mute=1`
            }else {
                return url;
            }
        },
        getBlackFilter(item){
            if(item.Filtro){
                return true
            }
        },
        fundoBox(fundo){
            if(fundo){
                return `background-color:rgba(0,0,0,.7)`;
            }
        }
    },
    data: function() {
        var listName = 'Banner'
        return {
            listName: listName,
            listUrl: `${_spPageContextInfo.siteServerRelativeUrl}lists/${listName}`,
            bannerHome: [],
            isMobile: window.innerWidth < 996 ? true : false
        }
    }
});