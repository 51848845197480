$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

Vue.component(`selos-home`, {
    data() {
        return {
            listURL: `${_spPageContextInfo.webServerRelativeUrl}/lists/Selos`,
            selos: [],
            msgError: null
        }
    },
    template: `
    <element>
        <div class="container">
            <template v-if="msgError">
                <p class="text-center py-3">{{ msgError }}</p>
            </template>
            <template v-else>
                <div class="selos-home row justify-content-center align-content-center align-items-center">
                    <div class="selo col-sm-2 text-center" v-for="item in selos">
                        <a v-if="item.Link" :href="item.Link" title="item.Title" target="_blank">
                            <img :src="item.LinkImagem" :alt="item.Title" />
                        </a>
                        <img v-else :src="item.LinkImagem" :alt="item.Title" />
                    </div>
                </div>
            </template>
        </div>
    </element>
    `,
    methods: {
        getSelos() {
            return $pnp.sp.web.getList(this.listURL).items
                .select(`Title, Ordem, Ativo, LinkImagem, Link`)
                .top(2)
                .orderBy(`Ordem`, true)
                .filter(`Ativo eq 1`)
                .get().then((results) => {
                    Vue.set(this, `selos`, results);
                }).catch((err) => {
                    this.threatError(err, `Erro ao carregar webpart de Selos.`)
                })
        },
        threatError(err, msg) {
            console.error(err);
            this.msgError = msg;
        }
    },
    created() {
        this.getSelos();
    },
});