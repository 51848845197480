$pnp.setup({
    headers: {
        "Accept": "application/json; odata=verbose"
    }
});

Vue.component(`reconhecimentos-home`, {
    data() {
        return {
            msgError: null,
            listURL: `${_spPageContextInfo.webServerRelativeUrl}/lists/Premios`,
            reconhecimentos: [],
        }
    },
    template:`
    <element>
        <div class="container">
            <template v-if="msgError">
                <p class="text-center">{{ msgError }}</p>
            </template>
            <template v-else>
                <div class="buttons">
                    <a href="javascript:" class="btn-prev"></a>
                    <a href="javascript:" class="btn-next"></a>
                </div>
                <template v-if="reconhecimentos.length > 5">
                    <div class="reconhecimentos-carousel">
                        <div class="logo" v-for="item in reconhecimentos">
                            <img :src="item.LinkImagem" :alt="item.Title" />
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="reconhecimentos row d-flex justify-content-center">
                        <div class="col-sm-2 text-center d-flex align-items-center" v-for="item in reconhecimentos">
                            <img :src="item.LinkImagem" :alt="item.Title" />
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </element>
    `,
    methods: {
        getReconhecimentos(){
            return $pnp.sp.web.getList(this.listURL).items
                .select(`Title, LinkImagem, Ordem`)
                .orderBy(`Ordem`, true)
                .get().then((results) => {
                    Vue.set(this, `reconhecimentos`, results);
                    if(this.reconhecimentos.length > 5){
                        this.mountSlick();
                    }
                }).catch((err) => {
                    this.threatError(err, `Erro ao carregar reconhecimentos.`)
                })
        },
        threatError(err, msg){
            console.log(err);
            this.msgError = msg;
        },
        mountSlick(){
            Vue.nextTick(() => {
                $(document).ready(() => {
                    $(`.reconhecimentos-carousel`).slick({
                        swipe:false,
                        arrows: true,
                        dots:false,
                        slidesToShow:5,
                        slidestoScroll: 1,
                        autoplay: true,
                        speed: 800,
                        prevArrow: $(`.btn-prev`),
                        nextArrow: $(`.btn-next`),
                        responsive: [
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow:1,
                                    slidestoScroll:1
                                }
                            }
                        ]
                    })
                })
            })
        }
    },
    created() {
        this.getReconhecimentos()
    },
});